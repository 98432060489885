<template>
  <match-media v-slot="{ desktop, desktopMin, tablet, mobile }">
    <aside class="realty__filters-mobile">
      <v-drawer :visible.sync="filtersVisible">
        <filters-mobile
          :default-filters="queryParams"
          :count="params.count"
          @close="filtersVisible = false"
          @apply-filters="applyFilters"
        />
      </v-drawer>
    </aside>
    <div class="realty">
      <div class="realty__header">
        <div class="realty__header-row">
          <v-page-title class="realty__title">
            Объекты недвижимости
            <span class="realty__top-count">{{ params.count }}</span>
          </v-page-title>
          <v-searchrow v-if="desktop" :value="queryParams.query" class="realty__search" @search="changeSearch" />
          <div :class="['realty__buttons', isRoleOwner && 'realty__buttons--owner']">
            <v-button v-if="!mobile" class="owners__button realty__button-filters" @click="toggleFilters">
              <v-icon-filters class="realty__button-icon realty__button-icon--filters" />
              {{ tablet || desktopMin ? '' : 'Фильтры' }}
            </v-button>
            <v-button v-if="!isRoleOwner" primary class="realty__button" @click="addObjectsRealty">
              <v-icon-plus class="realty__button-icon" />
              Добавить объект
            </v-button>
          </div>
        </div>
        <div v-if="!isRoleOwner" class="realty__tabs-wrapper">
          <v-tabs :value="queryParams.status" class="realty__tabs" :primary="mobile" @input="changeStatus">
            <v-tab
              v-for="(objectsRealtyStatus, index) of $options.OBJECTS_REALTY_STATUSES"
              :key="index"
              :name="objectsRealtyStatus.value"
            >
              {{ objectsRealtyStatus.label }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <div v-if="!desktop" class="realty__list-header">
        <h4 class="realty__list-title">
          Результаты поиска
          <span class="realty__count">{{ params.count }}</span>
        </h4>
        <v-searchrow v-if="!desktop" :value="queryParams.query" class="realty__search" @search="changeSearch" />
        <v-button v-if="mobile" class="realty__button realty__button--filters" @click="toggleFilters">
          <v-icon-filters class="realty__button-icon realty__button-icon--filters" />
          Фильтры и сортировка
        </v-button>
      </div>
      <section class="realty__list">
        <object-realty-list-item
          v-for="objectRealty in objectsRealtyList"
          :key="objectRealty.id"
          v-bind="objectRealty"
          :hide-edit="
            !isRoleAdmin &&
              queryParams.status !== $options.REALTY_STATUS_DRAFT &&
              objectRealty.agent &&
              objectRealty.agent.id !== currentUserId
          "
          :is-extended-view="isExtendedView"
          @edit="updateRealty(objectRealty)"
          @archive="archiveRealty(objectRealty)"
          @activate="activateRealty(objectRealty)"
          @remove="deleteRealty(objectRealty)"
        />
      </section>

      <div class="realty__pagination">
        <v-pagination :current="queryParams.page" :total="totalPages" @change="changePage" />
      </div>
    </div>
  </match-media>
</template>

<script>
import ObjectRealtyListItem from '@/components/ObjectsRealty/ListItem.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VButton from '@/components/common/VButton.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VPagination from '@/components/common/VPagination.vue'
import VIconFilters from '@/components/icons/VFilters.vue'
import FiltersMobile from '@/components/ObjectsRealty/Filters/Mobile.vue'
import VDrawer from '@/components/common/VDrawer.vue'
import VSearchrow from '@/components/common/VSearchrow.vue'
import { MatchMedia } from 'vue-component-media-queries'
import { mapGetters, mapState } from 'vuex'
import { advertsService } from '@/services/http'
import { loadingService } from '@/services/loading'
import confirmService from '@/services/confirmation'
import noticeService from '@/services/notification'
import { SORT_OPTIONS_MAP } from '@/constants/objectRealtyOptions'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { REALTY_STATUS_ACTIVE, REALTY_STATUS_ARCHIVE, REALTY_STATUS_DRAFT } from '@/constants/statuses/realty'
import { formatObjectRealty } from '@/utils/formatters'
import { loadStorageItem, saveStorageItem } from '@/services/storage'
import redirectIfNetworkIssue from '@/router/utils'

const STATUSES_MAP = [
  { label: 'Актуальное', value: REALTY_STATUS_ACTIVE },
  { label: 'Черновики', value: REALTY_STATUS_DRAFT },
  { label: 'Архив', value: REALTY_STATUS_ARCHIVE }
]

const IS_REALTY_LIST_EXTENDED_VIEW = 'isRealtyListExtendedView'

export default {
  REALTY_STATUS_DRAFT,
  OBJECTS_REALTY_STATUSES: STATUSES_MAP,
  SORT_OPTIONS: SORT_OPTIONS_MAP,
  name: 'ObjectsRealty',
  components: {
    ObjectRealtyListItem,
    VPageTitle,
    FiltersMobile,
    VButton,
    VIconPlus,
    VPagination,
    VSearchrow,
    VDrawer,
    MatchMedia,
    VIconFilters,
    VTabs,
    VTab
  },
  data() {
    return {
      isExtendedView: JSON.parse(loadStorageItem(IS_REALTY_LIST_EXTENDED_VIEW)),
      filtersVisible: false,
      loading: false,
      list: [],
      params: {
        limit: 15,
        count: 0
      }
    }
  },
  computed: {
    ...mapState(MODULE_SESSION, {
      currentUserId: state => state.model.id
    }),
    objectsRealtyList() {
      return this.list.map(formatObjectRealty).map(el => ({ ...el, status: this.queryParams.status }))
    },
    totalPages() {
      return Math.ceil(this.params.count / this.params.limit)
    },
    queryParams() {
      return {
        page: Number(this.$route.query.page) || 1,
        status: this.$route.query.status || REALTY_STATUS_ACTIVE,
        orderBy: this.$route.query.orderBy || '-id',
        query: this.$route.query.query || undefined,
        categoryId: Number(this.$route.query.categoryId) || undefined,
        agentId: Number(this.$route.query.agentId) || undefined,
        dateTo: this.$route.query.dateTo || undefined,
        dateFrom: this.$route.query.dateFrom || undefined,
        priceLte: Number(this.$route.query.priceLte) || undefined,
        priceGte: Number(this.$route.query.priceGte) || undefined,
        advertStatus: this.$route.query.advertStatus || undefined,
        dealType: this.$route.query.dealType || undefined,
        facingType: this.$route.query.facingType || undefined
      }
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isRoleOwner: `${MODULE_SESSION}/isRoleOwner`
    })
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchRealEstate()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.fetchRealEstate()
    })
    next()
  },
  methods: {
    updateQueryParams({ page, ...otherParams }) {
      this.$router.push({ query: { ...this.queryParams, ...{ page, ...otherParams } } })
    },
    changePage(page) {
      this.updateQueryParams({ page })
    },
    changeStatus(status) {
      this.updateQueryParams({ status })
    },
    changeOrder(orderBy) {
      this.updateQueryParams({ orderBy })
    },
    changeSearch(query) {
      this.updateQueryParams({ query })
    },
    changeView(isExtendedView) {
      this.isExtendedView = isExtendedView
      saveStorageItem(IS_REALTY_LIST_EXTENDED_VIEW, isExtendedView)
    },
    addObjectsRealty() {
      this.$router.push({ name: 'objects-realty-edit' })
    },
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible
    },
    search(filters) {
      this.updateQueryParams(filters)
    },
    applyFilters(filters) {
      this.filtersVisible = false
      this.updateQueryParams(filters)
    },

    fetchRealEstate() {
      const { page } = this.queryParams
      const { limit } = this.params
      const offset = (page - 1) * limit
      this.loading = true
      return advertsService
        .getList({ limit, offset, ...this.queryParams })
        .then(({ count, results }) => {
          this.list = results
          this.params = { ...this.params, count }
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
        })
    },
    updateRealty(objectRealty) {
      const { status } = this.queryParams
      this.$router.push({
        name: 'objects-realty-edit',
        params: { objectId: objectRealty.id, status }
      })
    },
    updateRealtyStatus({ id, isPublished }) {
      this.loading = true
      return advertsService.updateStatus(id, isPublished).finally(() => {
        this.loading = false
      })
    },
    archiveRealty(objectRealty) {
      confirmService
        .ask({ title: 'Вы точно хотите архивировать объект недвижимости?' })
        .then(() => {
          this.updateRealtyStatus({ id: objectRealty.id, isPublished: false })
            .then(() => {
              this.fetchRealEstate()
              noticeService.success('Объект недвижимости архивирован!')
            })
            .catch(() => noticeService.error('Не удалось архивировать объект недвижимости!'))
        })
        .catch(() => {})
    },
    activateRealty(objectRealty) {
      confirmService
        .ask({ title: 'Вы точно хотите активировать объект недвижимости?' })
        .then(() => {
          this.updateRealtyStatus({ id: objectRealty.id, isPublished: true })
            .then(() => {
              this.fetchRealEstate()
              noticeService.success('Объект недвижимости активирован!')
            })
            .catch(() => noticeService.error('Не удалось активировать объект недвижимости!'))
        })
        .catch(() => {})
    },
    deleteRealty(objectRealty) {
      confirmService
        .ask({ title: 'Вы точно хотите удалить объект недвижимости?' })
        .then(() => {
          this.loading = true
          advertsService
            .deleteDraft(objectRealty.id)
            .then(() => {
              this.fetchRealEstate()
              noticeService.success('Объект недвижимости удален!')
            })
            .catch(() => noticeService.error('Не удалось удалить объект недвижимости!'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
